// CSS
import '../scss/app.scss';

// // JS
// import * as jQuery from 'jquery';
// window.$ = jQuery;
// window.jQuery = jQuery;

// import Foundation from 'foundation-sites';

// document.addEventListener("DOMContentLoaded", function() {
//     window.jQuery(document).foundation();
// });

import './components/blog';
// import './components/cover';
// import './components/faq';
import './components/forms';
import './components/hashAnchors';
import './components/lazyload';
// import './components/logo';
import './components/modal';
import './components/monkeytail';
import './components/nav';
// import './components/pageheader';
// import './components/klanten';
import './components/polyfills';
// import './components/popup';
// import './components/portfolio';
import './components/header';
// import './components/search';
// import './components/scrollbar';
// import './components/springest';
import './components/swiper';
// import './components/table';
// import './components/tabs';
