document.addEventListener("DOMContentLoaded", function() {

    [].slice.call( document.querySelectorAll('.blog-archive__pagination a') ).forEach( (button) => {

        const pagination = button.parentElement;
        const container = button.parentElement.parentElement;
        const articlesContainer = container.querySelector('.blog-archive__articles');
		
		if ( button.classList.contains('no-xhr') ) {
			return;
		}

        button.addEventListener('click', function(e) {

            e.preventDefault();
            const href = this.href;

            fetch( this.href )
                .then(response => response.text())
                .then(text => {
                    const parser = new DOMParser();
                    const htmlDocument = parser.parseFromString(text, "text/html");
                    const newArticles = htmlDocument.documentElement.querySelectorAll('.blog-archive__articles article');
                    const newPagination = htmlDocument.documentElement.querySelector('.blog-archive__pagination');
                    const newPaginationButton = htmlDocument.documentElement.querySelector(".blog-archive__pagination a");

                    if ( newPaginationButton ) {
                        pagination.querySelector('a').href = newPaginationButton.href;
                    } else {
                        pagination.setAttribute('hidden', true);
                    }

                    for ( let article of newArticles ) {
                        articlesContainer.appendChild(article);
                    }

                    const event = new Event('DOMContentLoaded');
                    document.dispatchEvent(event);

                    // history.pushState(null, null, href);
                });

	    });
	});
});

// FX

function lazyLoadClickMoreButton() {
	var moreButtons = [].slice.call(document.querySelectorAll('.blog-archive__pagination a'));

	if ("IntersectionObserver" in window) {
		let moreButtonsObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
                    entry.target.click();
				}
			});
		});

		moreButtons.forEach(function(button) {
			moreButtonsObserver.observe(button);
		});
	}
}
document.addEventListener("DOMContentLoaded", lazyLoadClickMoreButton);
