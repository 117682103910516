document.addEventListener("DOMContentLoaded", function() {
	var elements = [].slice.call(document.querySelectorAll('.monkeytail'));

	elements.forEach( (el) => {

		let email = atob( el.dataset.email ); // innerHTML.split(' at ').join('@').split(' punt ').join('.');

		const div = document.createElement("div");
		div.innerHTML = email;
		email = div.textContent || div.innerText || "";

		el.setAttribute('href', 'mailto:' + email);
		el.innerHTML = el.innerHTML.split(' at ').join('@').split(' dot ').join('.');

	});

});