import Headroom from 'headroom.js';

document.addEventListener("DOMContentLoaded", function() {

    const headroom = new Headroom(document.querySelector("#header"), {
        offset: 100, // Math.floor( window.screen.availHeight / 4 )
    });

    headroom.init();

});
