// [data-src]

document.addEventListener("DOMContentLoaded", function() {
	var lazyImages = [].slice.call(document.querySelectorAll('img[data-src]'));

	if ("IntersectionObserver" in window) {
		let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					let lazyImage = entry.target;
					lazyImage.src = lazyImage.dataset.src;

					if ( lazyImage.dataset.srcset ) {
						lazyImage.srcset = lazyImage.dataset.srcset;
					}

					lazyImage.classList.remove("lazy");
					lazyImageObserver.unobserve(lazyImage);
				}
			});
		});

		lazyImages.forEach(function(lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}
});


// Foundation Interchange

function observeDataLazyInterchange() {
	var lazyImages = [].slice.call(document.querySelectorAll('[data-lazy-interchange]'));

	if ("IntersectionObserver" in window) {
		let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					let lazyImage = entry.target;
					lazyImage.dataset.interchange = lazyImage.dataset.lazyInterchange;
					delete lazyImage.dataset.lazyInterchange;
					lazyImage.classList.remove("lazy");
					lazyImageObserver.unobserve(lazyImage);
					jQuery(lazyImage).foundation();
				}
			});
		});

		lazyImages.forEach(function(lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}
}

// document.addEventListener("DOMContentLoaded", observeDataLazyInterchange);
// document.addEventListener("swiperInit", observeDataLazyInterchange);



// FX

function lazyLoadFxElements() {
	var lazyImages = [].slice.call(document.querySelectorAll('.fx'));

	if ("IntersectionObserver" in window) {
		let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
			let count = 0;
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					count++;
					let lazyEl = entry.target;
					setTimeout( function() {
						lazyEl.classList.add('animated');
					}, count * 50);
				}
			});
		});

		lazyImages.forEach(function(lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to a more compatible method here
		lazyImages.forEach(function(lazyImage) {
			lazyImage.classList.add('animated');
		});
	}
}
document.addEventListener("DOMContentLoaded", lazyLoadFxElements);
document.addEventListener("lazyLoadFxElements", lazyLoadFxElements);
