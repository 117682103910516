const getOffsetTop = element => {
    let offsetTop = 0;
    while(element) {
      offsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return offsetTop;
}

document.addEventListener("DOMContentLoaded", function() {

    [].slice.call( document.querySelectorAll('a[href^="#"]') ).forEach( (anchor) => {
        anchor.addEventListener('click', function(e) {

            if ( ! anchor.dataset.tab && anchor.hash.length > 1 && document.querySelectorAll( anchor.hash ).length ) {
                e.preventDefault();

                // const navHeight = document.querySelector('#header').clientHeight;
                const navHeight = 64;
                const target = document.querySelector( anchor.hash );

                if ( target.id && target.id == 'vervolg' ) {
                    document.querySelector('.vervolg').classList.toggle('is-visible');
                }
                else {
                    window.scrollTo({
                        top: getOffsetTop( target ) - navHeight,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            }

	    });
	});
});